





















import { CarFinish } from '@/api/interfaces/carfinish'
import { defineComponent } from '@vue/composition-api'
import { mapActions, mapState } from 'vuex'
import { ValidationObserver } from 'vee-validate'

export default defineComponent({
  name: 'SelectSearchCarFinish',
  components: {
    SelectSearch: () => import('../m-select-search.vue'),
    CreateEditCarFinish: () => import('../dialog/createEditCarFinish.vue'),
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => null,
    },
    carModel: {
      type: Object,
      required: false,
      default: () => null,
    },
  },
  data () {
    return {
      dialogCarFinish: false,
      carFinish: {} as CarFinish,
    }
  },
  computed: {
    ...mapState('carFinish', {
      carFinishList: 'list',
    }),
  },
  created () {
    this.loadFinition()
  },
  watch: {
    carModel () {
      this.loadFinition()
    },
  },
  methods: {
    ...mapActions('carFinish', {
      load: 'load',
      save: 'save',
    }),
    itemText (item: CarFinish) {
      // use this function instead of arrow function in item-text prop, workaround for https://github.com/vuetifyjs/vuetify/issues/11585
      return item.finish
    },
    loadFinition () {
      if (this.carModel?.id) this.load(this.carModel)
    },
    onInput (uri: string) {
      const carFinish = (this.carFinishList as Array<CarFinish>).find(
        x => x['@id'] === uri,
      )
      this.$emit('input', carFinish)
    },
    createFinish () {
      this.dialogCarFinish = !this.dialogCarFinish
    },
  },
})
